export default {
  data: () => ({
    citiesList: [
      {
        id: 1,
        name: "Cairo",
        position: { lat: 30.0561, lng: 31.2394 },
      },
      {
        id: 2,
        name: "Giza",
        position: { lat: 29.987, lng: 31.2118 },
      },
      {
        id: 3,
        name: "Alexandria",
        position: { lat: 31.2, lng: 29.9167 },
      },
      {
        id: 4,
        name: "Madinat as Sadis min Uktubar",
        position: { lat: 29.9361, lng: 30.9269 },
      },
      {
        id: 5,
        name: "Shubra al Khaymah",
        position: { lat: 30.1286, lng: 31.2422 },
      },
      {
        id: 5,
        name: "Al Mansurah",
        position: { lat: 31.05, lng: 31.3833 },
      },
      {
        id: 6,
        name: "Halwan",
        position: { lat: 29.8419, lng: 31.3342 },
      },
      {
        id: 7,
        name: "Al Mahallah al Kubra",
        position: { lat: 30.9667, lng: 31.1667 },
      },
      {
        id: 8,
        name: "Port Said",
        position: { lat: 31.25, lng: 32.2833 },
      },
      {
        id: 9,
        name: "Suez",
        position: { lat: 29.9667, lng: 32.5333 },
      },
      {
        id: 10,
        name: "Ţanta",
        position: { lat: 30.7833, lng: 31.0 },
      },
      {
        id: 11,
        name: "Asyut",
        position: { lat: 27.1869, lng: 31.1714 },
      },
      {
        id: 12,
        name: "Al Fayyum",
        position: { lat: 29.3, lng: 30.8333 },
      },
      {
        id: 13,
        name: "Az Zaqaziq",
        position: { lat: 30.5667, lng: 31.5 },
      },
      {
        id: 14,
        name: "Ismailia",
        position: { lat: 30.5833, lng: 32.2667 },
      },
      {
        id: 15,
        name: "Aswan",
        position: { lat: 24.0889, lng: 32.8997 },
      },
      {
        id: 15,
        name: "Kafr ad Dawwar",
        position: { lat: 31.1417, lng: 30.1272 },
      },
      {
        id: 16,
        name: "Damanhur",
        position: { lat: 31.05, lng: 30.4667 },
      },
      {
        id: 17,
        name: "Al Minya",
        position: { lat: 28.0833, lng: 30.75 },
      },
      {
        id: 18,
        name: "Damietta",
        position: { lat: 31.4167, lng: 31.8214 },
      },
      {
        id: 19,
        name: "Luxor",
        position: { lat: 25.6969, lng: 32.6422 },
      },
      {
        id: 20,
        name: "Qina",
        position: { lat: 26.1667, lng: 32.7167 },
      },
      {
        id: 21,
        name: "Suhaj",
        position: { lat: 26.5606, lng: 31.6917 },
      },
      {
        id: 22,
        name: "Bani Suwayf",
        position: { lat: 29.0667, lng: 31.0833 },
      },
      {
        id: 23,
        name: "Shibin al Kawm",
        position: { lat: 30.592, lng: 30.9 },
      },
      {
        id: 24,
        name: "Al Arish",
        position: { lat: 31.1249, lng: 33.8006 },
      },
      {
        id: 25,
        name: "Al Ghardaqah",
        position: { lat: 27.2578, lng: 33.8117 },
      },
      {
        id: 26,
        name: "Banha",
        position: { lat: 30.4628, lng: 31.1797 },
      },
      {
        id: 27,
        name: "Kafr ash Shaykh",
        position: { lat: 31.1, lng: 30.95 },
      },
      {
        id: 28,
        name: "Disuq",
        position: { lat: 31.1308, lng: 30.6479 },
      },
      {
        id: 29,
        name: "Bilbays",
        position: { lat: 30.4167, lng: 31.5667 },
      },
      {
        id: 30,
        name: "Mallawi",
        position: { lat: 27.7306, lng: 30.8425 },
      },
      {
        id: 31,
        name: "Idfu",
        position: { lat: 24.9781, lng: 32.8789 },
      },
      {
        id: 32,
        name: "Mit Ghamr",
        position: { lat: 30.7192, lng: 31.2628 },
      },
      {
        id: 33,
        name: "Munuf",
        position: { lat: 30.4667, lng: 30.9333 },
      },
      {
        id: 34,
        name: "Jirja",
        position: { lat: 26.3419, lng: 31.8894 },
      },
      {
        id: 35,
        name: "Akhmim",
        position: { lat: 26.5642, lng: 31.7461 },
      },
      {
        id: 36,
        name: "Zifta",
        position: { lat: 30.7119, lng: 31.2394 },
      },
      {
        id: 37,
        name: "Samalut",
        position: { lat: 28.3, lng: 30.7167 },
      },
      {
        id: 38,
        name: "Manfalut",
        position: { lat: 27.3128, lng: 30.9703 },
      },
      {
        id: 39,
        name: "Bani Mazar",
        position: { lat: 28.5, lng: 30.8 },
      },
      {
        id: 40,
        name: "Armant",
        position: { lat: 25.6167, lng: 32.5333 },
      },
      {
        id: 41,
        name: "Maghaghah",
        position: { lat: 28.6483, lng: 30.8422 },
      },
      {
        id: 42,
        name: "Kawm Umbu",
        position: { lat: 24.4667, lng: 32.95 },
      },
      {
        id: 43,
        name: "Bur Fuad",
        position: { lat: 31.2314, lng: 32.3253 },
      },
      {
        id: 44,
        name: "Al Quşiyah",
        position: { lat: 27.4403, lng: 30.8183 },
      },
      {
        id: 45,
        name: "Rosetta",
        position: { lat: 31.4014, lng: 30.4194 },
      },
      {
        id: 46,
        name: "Isna",
        position: { lat: 25.2933, lng: 32.5564 },
      },
      {
        id: 47,
        name: "Matruh",
        position: { lat: 31.3333, lng: 27.2333 },
      },
      {
        id: 48,
        name: "Abnub",
        position: { lat: 27.2667, lng: 31.15 },
      },
      {
        id: 49,
        name: "Hihya",
        position: { lat: 30.6687, lng: 31.5904 },
      },
      {
        id: 50,
        name: "Samannud",
        position: { lat: 30.9622, lng: 31.2425 },
      },
      {
        id: 51,
        name: "Dandarah",
        position: { lat: 26.1422, lng: 32.6697 },
      },
      {
        id: 52,
        name: "Al Kharjah",
        position: { lat: 25.44, lng: 30.55 },
      },
      {
        id: 53,
        name: "Al Balyana",
        position: { lat: 26.2329, lng: 31.9993 },
      },
      {
        id: 54,
        name: "Matay",
        position: { lat: 28.4167, lng: 30.7833 },
      },
      {
        id: 55,
        name: "Naj Hammadi",
        position: { lat: 26.05, lng: 32.25 },
      },
      {
        id: 56,
        name: "San al Hajar al Qibliyah",
        position: { lat: 30.9769, lng: 31.88 },
      },
      {
        id: 57,
        name: "Dayr Mawas",
        position: { lat: 27.6414, lng: 30.8494 },
      },
      {
        id: 58,
        name: "Ihnasya al Madinah",
        position: { lat: 29.0856, lng: 30.9344 },
      },
      {
        id: 59,
        name: "Daraw",
        position: { lat: 24.4003, lng: 32.9306 },
      },
      {
        id: 60,
        name: "Abu Qir",
        position: { lat: 31.3167, lng: 30.0667 },
      },
      {
        id: 61,
        name: "Faraskur",
        position: { lat: 31.3297, lng: 31.7147 },
      },
      {
        id: 62,
        name: "Ras Gharib",
        position: { lat: 28.3597, lng: 33.0775 },
      },
      {
        id: 63,
        name: "Al Husayniyah",
        position: { lat: 30.8612, lng: 31.919 },
      },
      {
        id: 64,
        name: "Safaja",
        position: { lat: 26.7517, lng: 33.9344 },
      },
      {
        id: 65,
        name: "Qiman al Arus",
        position: { lat: 29.3005, lng: 31.1683 },
      },
      {
        id: 66,
        name: "Qaha",
        position: { lat: 30.2833, lng: 31.2 },
      },
      {
        id: 67,
        name: "Al Karnak",
        position: { lat: 25.7184, lng: 32.6581 },
      },
      {
        id: 68,
        name: "Hirriyat Raznah",
        position: { lat: 30.6028, lng: 31.5372 },
      },
      {
        id: 69,
        name: "Al Qusayr",
        position: { lat: 26.1091, lng: 34.276 },
      },
      {
        id: 70,
        name: "Kafr Shukr",
        position: { lat: 30.547, lng: 31.2673 },
      },
      {
        id: 71,
        name: "Siwah",
        position: { lat: 29.2, lng: 25.5167 },
      },
      {
        id: 72,
        name: "Kafr Sad",
        position: { lat: 1.3557, lng: 31.6848 },
      },
      {
        id: 73,
        name: "Sharunah",
        position: { lat: 28.594, lng: 30.8516 },
      },
      {
        id: 74,
        name: "At Tur",
        position: { lat: 28.2333, lng: 33.6167 },
      },
      {
        id: 75,
        name: "Rafah",
        position: { lat: 31.282, lng: 34.2387 },
      },
      {
        id: 76,
        name: "Ash Shaykh Zuwayd",
        position: { lat: 31.2119, lng: 34.1106 },
      },
      {
        id: 77,
        name: "Bir al Abd",
        position: { lat: 31.0, lng: 33.0167 },
      },
    ],
  }),
};
